<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="background-color: #F7F5F9;">
		<BHLoading :show="bigScreenLoading" />
		<div class="dF jSB">
			<div class="dF aC mb-3 mt-3 mail-category">
				<h4>{{ sidebarSelection }} </h4>
				<a-tooltip title="Get new mail" overlayClassName="change-tooltip-color">
					<a-icon @click="cacheAllThreadMessages = {}; getMail()"
						class="mail-reload ml-2 cursor-pointer hover:text-primary"
						style="position: relative;top: -2px;font-size: 14px;" type="reload" />
				</a-tooltip>
			</div>
		</div>

		<a-row v-if="emailList.length" type="flex" class="w-full h-full relative">
			<BHLoading :show="loading" />
			<!-- List of All Messages -->
			<div class="hide-scrollbar left-email-bar" style="height: 100%;">

				<a-card class="mailHolder hide-scrollbar f1"
					style="overflow: scroll; height: calc(100% - 65px); cursor: pointer;">
					<div v-for="(mail) in emailList" :key="mail.id">
						<mailCard @showemail="showEmail" :email="mail" :current="selectMail.mail"
							@updateLoading="updateLoading" @removeThread="removeThread" />
					</div>
					<template v-if="!bigScreenLoading && !maxLoaded">
						<Observed @observed="loadMore" v-if="next_page_cursor" :key="moreLoaded">
							<div class="py-3 dF fC aC jC" style="background:#eee;">
								<a-icon type="loading" /> Loading
							</div>
						</Observed>
					</template>
				</a-card>
			</div>


			<div
				style="flex: 1; padding: 30px; border:1px solid #e8e8e8; border-left: none; height: 100%; background-color:#FAF9F9">

				<!-- If No Message Selected -->
				<a-card v-if="!selectMail.show" class="mailContent relative w-full h-full" />

				<!-- If Message Selected -->
				<div v-else class="w-full h-full">
					<mailContent :email="selectMail.mail" />
				</div>
			</div>
		</a-row>

		<template v-else>
			<EmptyApp
				v-if="(vendors.gmail && vendors.gmail.connectUrl && vendors.gmail.hasOwnProperty('active') && vendors.gmail.active) || (vendors.outlook && vendors.outlook.connectUrl && vendors.outlook.hasOwnProperty('active') && vendors.outlook.active) || (vendors.office365 && vendors.office365.connectUrl && vendors.office365.hasOwnProperty('active') && vendors.office365.active)"
				:text="'You do not have any emails'" :img="require('@/assets/emptyIcons/email.png')"
				:app="$store.state.theApp" />
			<EmptyApp v-else
				:text="'You haven\'t connected your email account.  To connect, go to Settings and select Email & Calendar Settings > Integrations.'"
				:img="require('@/assets/emptyIcons/email.png')" :app="$store.state.theApp" />
		</template>

	</div>
</template>

<script>
	import AEmpty from 'ant-design-vue/lib/empty'
	import BHLoading from 'bh-mod/components/common/Loading'
	import EmptyApp from 'bh-mod/components/common/EmptyApp'
	import mailCard from '@/components/common/mailCard'
	import mailContent from '@/components/common/mailContent'
	import MoreActions from 'bh-mod/components/common/MoreActions'
	import Observed from '@/components/common/Observed'


	export default {
		components: {
			AEmpty, BHLoading, EmptyApp, mailCard, mailContent, MoreActions, Observed
		},
		data() {
			return {
				bigScreenLoading: false,
				maxLoaded: false,
				loading: false,
				email: {
					to: [],
					from: {},
					reply: '',
				},
				selectMail: {
					mail: {},
					show: false,
				},
				showReply: {
					show: false,
					mail: {},
				},
				cacheAllThreadMessages: {}
			}
		},
		watch: {

		},
		computed: {
			vendors() {
				return this.$store.state.contacts.vendors || {}
			},
			moreLoaded() {
				return this.$store.state.contacts.moreLoaded
			},
			lastDate() {
				if (!this.emailList.length) return 0
				let last = this.emailList[0]
				let lastMessage = last.messages[last.messages.length - 1]
				return (new Date(lastMessage?.date).getTime() / 1000)
			},
			next_page_cursor() {
				return this.$store.state.contacts.inbox_next_page_cursor
			},
			openedThreads() {
				return this.$store.state.contacts.openedThreads
			},
			emailList() {
				return this.$store.state.contacts.inbox
			},
			sidebarSelection() {
				return this.$store.state.sidebarSelection
			},
			instance() {
				return this.$store.state.instance
			},
			nylasAccount() {
				return this.$store.state.contacts.nylasAccount || {}
			}
		},
		methods: {
			removeThread(data) {
				let index = this.emailList.findIndex(x => x.id == data.id)
				if (index != -1) {
					this.emailList.splice(index, 1)
				}
			},
			updateLoading(load) {
				this.bigScreenLoading = load;
			},
			getMail() {
				let lastDate = this.lastDate
				this.bigScreenLoading = true
				this.$api.get(`/contacts/:instance/inbox/v3`).then(({ data }) => {
					if (data.hasOwnProperty('success') && !data.success) {
						this.$notification['error']({
							message: 'No Email Connection',
							description: 'You have not connected your mail account. To connect, go to Settings and select Email & Calendar Settings > Integrations.',
							duration: 5
						});
					} else {
						if (lastDate) {
							this.$store.commit('NEW_INBOX', data.data)
						} else {
							this.$store.commit('STORE_INBOX', data.data)
						}
						this.$store.commit('STORE_INBOX_NEXT_PAGE_CURSOR', data.next_page_cursor)
					}
					this.bigScreenLoading = false
				}).catch(err => {
					this.bigScreenLoading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			},
			loadMore() {
				this.$api.get(`/contacts/:instance/inbox/v3?page_token=${this.next_page_cursor}`).then(({ data }) => {
					if (data.hasOwnProperty('success') && !data.success) {
						this.$notification['error']({
							message: 'No Email Connection',
							description: 'You have not connected your Gmail account.',
							duration: 5
						});
					} else {
						if (data?.data?.length) {
							this.$store.commit('UPDATE_INBOX', data.data)
							this.$store.commit('INCREASE_MORE_LOADED')
						} else if (!data?.next_page_cursor) {
							this.maxLoaded = true
						}
						this.$store.commit('STORE_INBOX_NEXT_PAGE_CURSOR', data.next_page_cursor)
					}
					this.loading = false
				}).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			},

			async showEmail(obj) {
				if (obj.messages[0].id != this.selectMail.mail.id) {
					this.showReply = {
						show: false,
						mail: {}
					}
				}
				obj.messages = await this.getAllMessages(obj.id)

				if (!this.openedThreads.map(x => x = x.id).includes(obj.id)) {
					this.loading = true
					if (obj.unread) {
						this.$api.put(`/contacts/:instance/threads/${obj.id}/read`).then(({ data }) => {
							obj.unread = false
							this.selectMail.mail = obj
							this.selectMail.show = true
							this.$store.commit('ADD_THREAD', obj)
							this.loading = false
						}).catch(err => {
							this.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
					} else {
						this.selectMail.mail = obj
						this.selectMail.show = true
						this.$store.commit('ADD_THREAD', obj)
						this.loading = false
					}
				} else {
					if (obj.unread) {
						this.$api.put(`/contacts/:instance/threads/${obj.id}/read`).then(({ data }) => {
							obj.unread = false
							let findThread = this.openedThreads.find(x => x.id == obj.id)
							this.selectMail.mail = findThread
							this.selectMail.show = true
						}).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
					} else {
						let findThread = this.openedThreads.find(x => x.id == obj.id)
						this.selectMail.mail = findThread
						this.selectMail.show = true
					}
				}
			},

			// Fetch all the message for the selected mail as new v3 API not giving all the message
			async getAllMessages(thread_id) {
				if (this.cacheAllThreadMessages[thread_id]) {
					return this.cacheAllThreadMessages[thread_id]
				}
				let messages = [];
				let next_page_cursor = null;
				do {
					try {
						this.loading = true
						let page_token = next_page_cursor ? `&page_token=${next_page_cursor}` : ''
						const { data } = await this.$api.get(`/contacts/:instance/messages?thread_id=${thread_id}${page_token}`)
						if (data?.data?.length) {
							messages.push(...data.data);
						}
						next_page_cursor = data.next_page_cursor;
						this.loading = false
					} catch (err) {
						this.loading = false;
						next_page_cursor = null;
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					}
				} while (next_page_cursor);

				this.cacheAllThreadMessages[thread_id] = messages.reverse()

				return this.cacheAllThreadMessages[thread_id];
			}
		},
		created() {
			this.$store.commit('STORE_INBOX_NEXT_PAGE_CURSOR', null)
			if(this.nylasAccount?.email) {
				this.getMail()
			}

			this.$store.commit('SIDEBAR_SELECTION', { value: 'Inbox' })
		}

	}
</script>
<style lang="scss" module>
	@import "./style.module.scss";
</style>
<style lang="scss" scoped>
	.left-email-bar {
		width: 280px;

		@media screen and (min-width: 1300px) {
			width: 400px;
		}
	}

	.contacts-page {
		@media screen and (min-width:567px) {
			margin-top: 5%;
		}
	}

	.aB {
		align-items: baseline;
	}

	.mail-reload {
		opacity: 1;
	}

</style>

<style lang="scss">
	.mailHolder {
		height: 100%;

		.ant-card-body {
			padding: 0;
		}
	}

	.mailHolder.ant-card.ant-card-bordered {
		border: none;
	}

	.mailContent .ant-card-body {
		padding: 48px;
	}

	.replyBox {
		border-top: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom: none;

		&:focus {
			border-color: #d9d9d9;
			border-right-width: 0;
			box-shadow: none;
			-webkit-box-shadow: none;
		}

		&:hover {
			border-color: #d9d9d9;
		}
	}
</style>
